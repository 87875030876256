import { Result, Button } from "antd";
import { FormattedMessage } from "react-intl";

function NotFound() {
  return (
    <Result
      status="404"
      title={
        <FormattedMessage id="error.notFound" defaultMessage="Not Found" />
      }
      subTitle={
        <FormattedMessage
          id="error.notFound.message"
          defaultMessage="Sorry, the page you visited does not exist."
        />
      }
      extra={[
        <Button href="/" type="primary" key="console">
          <FormattedMessage id="error.backToHome" defaultMessage="Back Home" />
        </Button>,
      ]}
    />
  );
}

export default NotFound;
